import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import StartProject from '../components/Common/StartProject'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import {Link} from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Inscription from '../components/Newsletter/Inscription'


const kebabCase = string => string
    .normalize("NFD").replace(/\p{Diacritic}/gu, "")
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase();


const Blog = ({ data }) => {
    //console.log(data)
    return (
        <Layout>
            <Helmet>
                <title>Blog Seekreet | Infos et actus data management</title>
                <meta name="description" content="Découvrez nos articles et actualités sur les thématiques compliance, QHSE - qualité, Food Safety Culture (FSC), certification, partage d’informations, digitalisation des process..." />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Blog et Actualités" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="Blog" 
            />

            <section className="blog-details-area ptb-30 bg-e3fbff">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="widget-area" style={{padding:"0"}}>
                                {/** Affichage des catégories */}
                                <div className="widget widget_categories">
                                    <h3 className="widget-title">Catégories</h3>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className="tagcloud-custom">
                                            {data.cats.group.map(cat => (
                                                <Link to={"/cat/"+kebabCase(cat.category)} className={"default "+kebabCase(cat.category)} key={cat.category}>
                                                    {cat.category} <span className="tag-link-count"></span>
                                                    {/* {"("+cat.totalCount+")"}</span> */}
                                                </Link>
                                            ))}
                                            <Link to='/livres-blancs' className="livre-blanc">Livres Blancs</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="blog-area pt-30 pb-100 bg-e3fbff">
                <div className="container">
                    <div className="row">
                        {
                            data.allMdx.nodes.map(node => (
                                <div className="col-lg-4 col-md-6">
                                    <div className="single-blog-post">
                                        <div className="post-image" key={node.id}>
                                            <Link to={"/"+node.frontmatter.slug}>
                                                <GatsbyImage
                                                    image={getImage(node.frontmatter.hero_image)}
                                                    alt={node.frontmatter.hero_image_alt}
                                                />
                                            </Link>
                                        </div>
                                        <div className="post-content">
                                            <ul className="post-meta d-flex justify-content-between align-items-center">
                                                <li className={"default "+kebabCase(node.frontmatter.category)}>
                                                    <Link to={"/cat/"+kebabCase(node.frontmatter.category)}>
                                                        {node.frontmatter.category}
                                                    </Link>
                                                </li>  
                                                <li>
                                                    <i className='bx bx-calendar'></i> {node.frontmatter.date}
                                                </li>
                                            </ul>
                                            <h3>
                                                <Link to={"/"+node.frontmatter.slug}>
                                                    {node.frontmatter.title}
                                                </Link>
                                            </h3>
                                            <p className='time-to-read'>{"Temps de lecture : "+node.frontmatter.readTime}</p>
                                            <p>{node.frontmatter.teaser}</p>
                                            <div className='follow-link'>
                                                <Link to={"/"+node.frontmatter.slug}>
                                                    <i className="bx bx-right-arrow-alt"></i> Lire la suite <span></span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            {/* <div className="col-lg-12 col-md-12">
                <div className="pagination-area text-center">
                    <Link to="#" className="prev page-numbers">
                        <i className='bx bx-chevrons-left'></i>
                    </Link>
                    <span className="page-numbers current" aria-current="page">1</span>
                    <Link to="#" className="page-numbers">
                        2
                    </Link>
                    <Link to="#" className="page-numbers">
                        3
                    </Link>
                    <Link to="#" className="page-numbers">
                        4
                    </Link>
                    <Link to="#" className="next page-numbers">
                        <i className='bx bx-chevrons-right'></i>
                    </Link>
                </div>
            </div>
            <br /> */}

            <Inscription />
            <StartProject />
            <Footer />
        </Layout>
    );
}

export const query = graphql`
query MyQuery {
    allMdx (sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          id
          frontmatter {
            metaTitle
            metaDescription
            teaser
            readTime
            slug
            date(formatString: "MMM YYYY", locale: "fr")
            author
            category
            title
            hero_image {
                childImageSharp {
                  gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
          }
        }
    }
    cats: allMdx{
        group(field: frontmatter___category) {
			category: fieldValue
            totalCount
        }
    }
}
`


export default Blog;